import axios from 'axios';
import { baseURL } from "./aws-config";
import ErrorToast from 'components/Toaster/ErrorToast';

// Create an Axios instance
const api = axios.create({
  baseURL: baseURL,
});

// Interceptor to check token expiration before requests
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    const tokenExpiration = localStorage.getItem('token_expiration');
    const expirationTime = tokenExpiration ? parseInt(tokenExpiration) : null;

    const isTokenExpired = () => {
      if (!accessToken || !expirationTime) return true;
      const currentTime = new Date().getTime();
      return currentTime > expirationTime;
    };

    if (isTokenExpired()) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_expiration');
      window.location.href = '/login';
      return Promise.reject(new Error('Token expired'));
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle 401 responses
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_expiration');
      <ErrorToast message='Your session has expired. Please log in again'/>
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
