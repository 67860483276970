import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../api'

const MenteeDashboardCard = () => {
  const [menteeData, setMenteeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;
  const accessToken = localStorage.getItem("access_token");

  const handleModuleClick = (id) => {
    navigate(`/dashboard/workspace/${id}`);
  };

  const fetchMenteeData = async () => {
    try {
      const response = await api.get('dashboard/');
      setMenteeData(response?.data);
      localStorage.setItem("menteeData", JSON.stringify(response?.data));
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
       <ErrorToast message="Your session has expired. Please log in again."/>
      } else {
        <ErrorToast message={'Failed to fetch mentee data'}/>
      }
      setError("Failed to fetch mentee data");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userRole === "mentee") {
      fetchMenteeData();
    }
  }, [accessToken]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: 'Be Vietnam Pro'
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  if (!menteeData || menteeData?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          fontFamily: 'Be Vietnam Pro'
        }}
      >
          <Typography variant="h6" sx={{fontFamily: 'Be Vietnam Pro', textAlign: 'center', marginTop: '20px'}}>
          No Data Available</Typography>
      </Box>
    );
  }

  const {
    id,
    name,
    description,
    is_completed,
    progress,
    curent_module,
    next_module,
  } = menteeData || {};
  return (
    <Grid item xs={12} sm={12} md={12} lg={9} mb={3}>
      <Card sx={{ borderRadius: "5px", border: "none", height: "auto" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box display={"flex"} flexDirection={"row"} gap={2}>
              <Typography sx={{ color: "#595A5F", fontSize: "12px" }}>
                {/* {curent_module?.module} Modules */}
              </Typography>
              <Typography sx={{ color: "#595A5F", fontSize: "12px" , fontFamily: 'Be Vietnam Pro'}}>
                Started {new Date(menteeData?.timestamp).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent={"space-between"}
            mt={2}
          >
            <Box width={{ xs: "100%", sm: "70%" }} mr={2}>
              <Typography
                sx={{
                  color: "#202124",
                  fontSize: { xs: "18px", sm: "24px" },
                  fontWeight: 500,
                  fontFamily: 'Be Vietnam Pro'
                }}
              >
                {name}
              </Typography>
              <Typography sx={{ color: "#595A5F", fontSize: "12px", mt: 1, fontFamily: 'Be Vietnam Pro' }}>
                {description}
              </Typography>
            </Box>
            <Box width={{ xs: "100%", sm: "30%" }} mt={{ xs: 2, sm: 0 }}>
              <Typography
                sx={{
                  marginRight: 1,
                  fontSize: "10px",
                  color: "#595A5F",
                  fontFamily: 'Be Vietnam Pro'
                }}
              >
                {`${progress}% completed`}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{ flex: 1, mr: 4, borderRadius: "5px", width: "100%" }}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={2} mb={1}>
            <Typography sx={{ color: "#595A5F", fontSize: "12px" , fontFamily: 'Be Vietnam Pro'}}>
              Current Module
            </Typography>
            <Typography
              sx={{
                backgroundColor: "#EBEBEB",
                borderRadius: "5px",
                color: "#202124",
                width: "fit-content",
                padding: "4px",
                fontSize: { xs: "12px", sm: "14px" },
                mt: 1,
                px: 1,
                cursor: "pointer",
                fontFamily: 'Be Vietnam Pro'
              }}
              onClick={() => handleModuleClick(menteeData?.id)}
            >
              Module {curent_module?.module} : {curent_module?.title}
            </Typography>
          </Box>
          <Box mt={2} mb={1}>
            <Typography sx={{ color: "#595A5F", fontSize: "12px", fontFamily: 'Be Vietnam Pro' }}>
              Coming Up Next
            </Typography>
            <Typography
              sx={{
                backgroundColor: "#EBEBEB",
                borderRadius: "5px",
                color: "#202124",
                width: "fit-content",
                padding: "4px",
                fontSize: { xs: "12px", sm: "14px" },
                mt: 1,
                px: 1,
                cursor: "pointer",
                fontFamily: 'Be Vietnam Pro'
              }}
              
              onClick={() => handleModuleClick(menteeData?.id)}
            >
              Module {next_module?.module} : {next_module?.title}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MenteeDashboardCard;
