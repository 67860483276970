import React, { useState, useEffect } from "react";
import Split from "react-split";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeLight } from "@uiw/codemirror-theme-vscode";
import { javascript } from "@codemirror/lang-javascript";
import { cpp } from "@codemirror/lang-cpp";
import { python } from "@codemirror/lang-python";
import { go } from "@codemirror/lang-go";
import { java } from "@codemirror/lang-java";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Divider } from "@mui/material";
import PreferenceNav from "./PreferenceNavbar/PreferenceNavbar";
import ProblemDiscription from "../ProblemDiscription/ProblemDiscription";
import NavigationBar from "../../NavigationBar/NavigationBar";
import { baseURL } from "../../../aws-config";
import "./Playground.css";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../../../api'

const Workspace = () => {
  const [language, setLanguage] = useState("python");
  const [code, setCode] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  const boilerPlates = "//Write your code here";

  const languageExtensions = {
    javascript: javascript(),
    cpp: cpp(),
    python: python(),
    java: java(),
    go: go(),
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await api.get(`course/${selectedModuleId}/p/`);
        const fetchedQuestions = response?.data || [];
        setQuestions(fetchedQuestions);
        if (fetchedQuestions?.length > 0) {
          const firstQuestion = fetchedQuestions[0];
          setCode(
            firstQuestion?.submission_data?.length > 0
              ? firstQuestion?.submission_data[0]?.submission
              : boilerPlates[language]
          );
        }
      } catch (error) {
        <ErrorToast message={error}/>
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [language]);

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion) return;
  
    try {
      await api.post(
        `course/${selectedModuleId}/p/${currentQuestion?.id}/`,
        { submission: code }
      );
      const updatedQuestions = questions?.map((question, index) =>
        index === currentQuestionIndex
          ? {
              ...question,
              submitted: true,
              submission_data: [{ submission: code }],
            }
          : question
      );
      setQuestions(updatedQuestions);
    } catch (error) {
      <ErrorToast message={error}/>
      setError(error);
    }
  };

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleNext = async () => {
    await handleSubmit();
    if (currentQuestionIndex < questions?.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (questions?.length > 0) {
      const currentQuestion = questions[currentQuestionIndex];
      setCode(
        currentQuestion?.submission_data?.length > 0
          ? currentQuestion?.submission_data[0]?.submission
          : // : boilerPlates[language]
            boilerPlates
      );
    }
  }, [currentQuestionIndex, questions, language]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="error-message">
        <p>{error?.message}</p>
      </div>
    );
  }

  return (
    <div>
      <NavigationBar
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
      <Divider
        sx={{
          borderColor: "black",
          borderWidth: "1px",
          width: "100%",
          marginBottom: "0px",
        }}
      />
      <Split className="split" minSize={500} sizes={[50, 50]}>
        <div className="split-pane">
          <ProblemDiscription problem={questions[currentQuestionIndex]} />
        </div>
        <div className="split-pane-code">
          <div className="playground-container">
            <PreferenceNav setLanguage={setLanguage} />
            <div className="code-editor">
              <CodeMirror
                value={code}
                theme={vscodeLight}
                extensions={[languageExtensions[language]]}
                style={{ fontSize: 16, height: "calc(75vh - 2.75rem)" }}
                onChange={handleCodeChange}
              />
            </div>
            <div className="button-container">
              <Button className="button" onClick={handleSubmit}>
                Submit Code
              </Button>
            </div>
          </div>
        </div>
      </Split>
    </div>
  );
};

export default Workspace;
