import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import { useSelector } from "react-redux";
import Toaster from "../components/Toaster/Toaster";
import SuccessModal from "../components/SuccessModal";
import api from "../api";
import ErrorToast from "components/Toaster/ErrorToast";

const AssignmentMentee = () => {
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("success");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); 
  const [successMessage, setSuccessMessage] = useState(""); 

  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const endpoint = `course/${selectedModuleId}/mentee/assignments/`;

        const response = await api.get(endpoint);

        setAssignments(response?.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch assignments");
        setLoading(false);
      }
    };

    fetchAssignments();
  }, [selectedModuleId, accessToken]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile?.name : "No file chosen");
  };

  const handleUpload = async (assignmentId) => {
    if (!file) return;

    try {
      const { url, fields } = await api.get(`course/${selectedModuleId}/assignment-presigned/`, {
        params: { filename: file.name },
      }).then(response => response.data);

      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      formData.append("file", file);

      await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      await api.post(
        `course/${selectedModuleId}/mentee/assignments/${assignmentId}/`,
        { submission_file: fields.key }
      );

      setSuccessMessage("File uploaded successfully");
      setToasterMessage("File uploaded successfully");
      setToasterSeverity("success");
      setIsSuccessModalOpen(true);
    } catch (error) {
      setToasterMessage("Failed to upload file");
      setToasterSeverity("error");
    } finally {
      setToasterOpen(true);
    }
  };

  const handleDownload = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("File download error:", error);
      <ErrorToast message={`Error downloading the file: ${error}`} />;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {assignments?.map((assignment, index) => (
          <Grid item xs={12} key={assignment?.id}>
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 3 }}>
              <Box sx={{ ml: 2, flex: 1 }}>
                <Typography variant="h6">{assignment?.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {assignment?.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownload(
                        assignment?.reference_url,
                        assignment?.title
                      );
                    }}
                    style={{ color: "#0181BB", textDecoration: "none" }}
                  >
                    View More
                  </a>
                </Typography>

                {assignment?.submission_data?.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="textSecondary">
                      Submitted on: {new Date(assignment?.submission_data[0]?.submitted_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(
                            assignment?.submission_data[0]?.submission_file,
                            assignment?.title
                          );
                        }}
                        style={{ color: "#0181BB", textDecoration: "none" }}
                      >
                        Download Submission
                      </a>
                    </Typography>
                  </Box>
                )}

                <Card
                  sx={{
                    mt: 2,
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    width: "50% !important",
                  }}
                >
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<UploadIcon />}
                    sx={{ mr: 2 }}
                  >
                    Choose File
                    <input type="file" hidden onChange={handleFileChange} />
                  </Button>
                  <Typography variant="body2" color="textSecondary">
                    {fileName}
                  </Typography>
                </Card>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleUpload(assignment?.id)}
                  disabled={!file}
                  sx={{ mt: 1 }}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onClose={() => setToasterOpen(false)}
      />
      <SuccessModal
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={successMessage}
      />
    </>
  );
};

export default AssignmentMentee;
