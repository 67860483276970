import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../api";
import { useSelector } from "react-redux";

const PreviousAssessment = () => {
  const [fetchingPreviousAssessment, setFetchingPreviousAssessment] =
    useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  useEffect(() => {
    const fetchPreviousAssessment = async () => {
      setFetchingPreviousAssessment(true);
      try {
        const response = await api.get(
          `course/${selectedModuleId}/previous-assessments/`
        );
        setResponseData(response?.data || []);
      } catch (error) {
        setError(`Failed to generate skills: ${error?.message}`);
      } finally {
        setFetchingPreviousAssessment(false);
      }
    };

    fetchPreviousAssessment();
  }, [selectedModuleId]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  if (fetchingPreviousAssessment) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorToast message={error} />;
  }

  if (responseData.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontFamily: "Be Vietnam Pro", fontSize: "18px", mt: 2 }}
        >
          No previous assessments available.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {responseData?.map((assessment, index) => (
        <Grid item xs={12} key={index}>
          {assessment?.timestamp ? (
            <Box
              sx={{
                backgroundColor: "#DDF4FFBA",
                color: "#595A5F",
                padding: "8px",
                width: "fit-content",
                height: "39px",
                fontSize: "16px",
                borderRadius: "8px",
                textAlign: "center",
                fontFamily: "Be Vietnam Pro",
                mt: 2,
              }}
            >
              Created on {formatDate(assessment?.timestamp)}
            </Box>
          ) : (
            " "
          )}

          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Be Vietnam Pro",
                  color: "#202124",
                  mb: 2,
                }}
              >
                {assessment?.description}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Be Vietnam Pro",
                  color: "#202124",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                Options:
              </Typography>
              {Object.keys(assessment.options_data)?.map((optionKey) => {
                const option = assessment?.options_data[optionKey];
                return (
                  <Box key={optionKey} sx={{ mb: 1 }}>
                    <ul>
                      <li>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Be Vietnam Pro",
                            color: "#595A5F",
                          }}
                        >
                          {option?.option_text}
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PreviousAssessment;
