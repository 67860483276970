import React from "react";
import left_arrow from "../../assets/chatImages/left_arrow.svg";
import "../index.css";
import { useNavigate } from "react-router-dom";

function SideBar() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard");
  };
  return (
    <div className="sidebar">
      <button onClick={handleClick} className="logo-button">
        <img src={left_arrow} alt="Logo" />
      </button>
    </div>
  );
}

export default SideBar;
