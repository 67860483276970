import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import { AuthProvider } from "./AuthContext"; // Import AuthProvider
import Login from "Homepage/Login";
import Homepage from "../src/Homepage/index";
import ModalComponent from "Homepage/components/RegistrationForm";
import PrivateRoutes from "./PrivateRoute";
import Calendar from "Homepage/components/Calendar";
import CallScheduled from "Homepage/components/CallScheduled";
import { Provider } from "react-redux";
import { store } from "./store";
// import Profile from "Homepage/components/Profile";
import AboutUs from "Homepage/AboutUs/AboutUs";
import Dashboard from "dashboard";
import MenteeDashboard from "dashboard/MenteeDashboard";
import Workspace from "workspace";
import NewHomepage from "new-homepage/Homepage";
import ChatApp from "../src/Chat/App.js";
import Profile from "../src/Profile/App.js";
import CalendarMeeting from "new-homepage/CalendarMeeting";

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<NewHomepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard/workspace/:id" element={<Workspace />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/calendar-meeting" element={<CalendarMeeting />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/chat" element={<ChatApp />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
