import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
} from "@mui/material";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import "./SubjectiveQuestion.css";
import { baseURL } from "aws-config";
import ReactMarkdown from "react-markdown";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../../api'

const MultipleChoiceQuestion = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await api.get(`course/${selectedModuleId}/o/`);
      const formattedQuestions = response?.data?.map((question) => ({
        id: question?.id,
        text: question?.description,
        options: question?.mcq_answers?.map((answer) => ({
          id: answer?.id,
          text: answer?.option,
        })),
        submissionData: question?.submission_data,
        submitted: question?.submitted,
      }));
      setQuestions(formattedQuestions);
      setSubmittedAnswers(
        response?.data?.map((question) =>
          question?.submission_data?.length > 0
            ? question?.submission_data[0]?.submission
            : null
        )
      );
    } catch (error) {
      <ErrorToast message={error}/>
      setError(error);
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    const selectedOptionId = selectedOption;
    try {
      await api.post(`course/${selectedModuleId}/o/${currentQuestion?.id}/`, {
        submission: selectedOptionId,
      });
      const updatedAnswers = [...submittedAnswers];
      updatedAnswers[currentQuestionIndex] = selectedOptionId;
      setSubmittedAnswers(updatedAnswers);
      setSelectedOption(null);
      fetchQuestions();
    } catch (error) {
      <ErrorToast message={error}/>
      setError(error);
    }
  };
  
  const handleNext = () => {
    setSelectedOption(submittedAnswers[currentQuestionIndex + 1] || null);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setSelectedOption(submittedAnswers[currentQuestionIndex - 1] || null);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error.message}
      </Typography>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div>
      <NavigationBar
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions?.length}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
      <Divider
        sx={{
          borderColor: "black",
          borderWidth: "1px",
          width: "100%",
          marginBottom: "16px",
        }}
      />
      <div className="paper">
        <Box className="question-box">
          <ReactMarkdown>{currentQuestion?.text}</ReactMarkdown>
        </Box>
        <div className="options-container">
          <FormControl component="fieldset">
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              {currentQuestion?.options?.map((option) => (
                <FormControlLabel
                  className={`option-box ${
                    submittedAnswers[currentQuestionIndex] === option?.id
                      ? "submitted-option"
                      : ""
                  }`}
                  key={option?.id}
                  value={option?.id}
                  control={<Radio />}
                  label={option?.text}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <Box
          className="submit-button-container"
          sx={{ marginTop: "300px", marginLeft: "1520px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="submit-button"
          >
            Submit
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default MultipleChoiceQuestion;
