// Chat.js
import React from "react";
import Top from "./Top";
import MessageSpace from "./MessageSpace";
import Bottom from "./Bottom";
import edgent_logo from "../../assets/chatImages/edgent_logo.svg";

function Chat({ selectedChat, handleBackClick }) {
  if (!selectedChat) {
    return (
      <div className="no-chat-selected">
        <img
          src={edgent_logo}
          alt="Logo"
          style={{ width: "250px", height: "250px" }}
        />
      </div>
    );
  }

  return (
    <div className="chat">
      <Top chat={selectedChat} handleBackClick={handleBackClick} />
      <MessageSpace chat={selectedChat} />
      <Bottom chat={selectedChat} />
    </div>
  );
}

export default Chat;
