import ChatList from "./components/ChatList";
import SideBar from "./components/SideBar";
import Chat from "./components/Chat";
import "./index.css";
import { useState } from "react";
import { UserProvider } from "./context/UserContext";

const ChatApp = () => {
  const [selectedChat, setSelectedChat] = useState(null);

  const handleChatSelection = (chat) => {
    setSelectedChat(chat);
  };

  const handleBackClick = () => {
    setSelectedChat(null);
  };

  return (
    <UserProvider>
      <div className={`main2 ${selectedChat ? "chat-visible" : ""}`}>
        <SideBar />
        <ChatList setSelectedChat={handleChatSelection} />
        <Chat selectedChat={selectedChat} handleBackClick={handleBackClick} />
      </div>
    </UserProvider>
  );
};

export default ChatApp;
