import React from "react";
import dp from "../../assets/chatImages/dp.svg";
import { RiArrowLeftDoubleLine } from "react-icons/ri";

function Top({ chat, handleBackClick }) {
  return (
    <div className="title-bar">
      <div className="chat-item2">
        <button className="back-button" onClick={handleBackClick}>
          <RiArrowLeftDoubleLine />
        </button>
        <div className="profile-pic2">
          <img src={dp} alt="John Doe" />
        </div>
        <div className="chat-details2">
          <div className="chat-name2">{chat.name}</div>
          <div className="chat-message2">{chat.location}</div>
        </div>
      </div>
    </div>
  );
}

export default Top;
