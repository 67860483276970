import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import AIIcon from "../assets/homepage/artificialAI.svg";
import path from "../assets/homepage/path.svg";
import mentor from "../assets/homepage/mentorIcon.svg";
import engineer from "../assets/homepage/softwareEngineering.svg";
import WhoIsItForContent from "Homepage/components/WhoIsItContent";
import EdgentOfferings from "./EdgentOfferings";
import HowItWorks from "./HowItWorks";
import MentorshipSection from "./Testmonials";
import Footer from "./Footer";
import CallToAction from "./CallToAction";
import { Link } from "react-router-dom";
import backgroundImage from "../assets/homepage/backgroundImage.svg";
import edgentWhite from "../assets/homepage/edgentWhite.svg";
import axios from "axios";
import { dataScienceCourseLink, SDECourseLink } from "../../src/aws-config";
import { useNavigate } from "react-router-dom";
import ErrorToast from "components/Toaster/ErrorToast";

const HeroContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 6),
  textAlign: "center",
}));

const Card = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const NewHomepage = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);

  const handleJoinNow = () => {
    if (!accessToken) {
      navigate("/login");
    } else {
      navigate("/calendar-meeting");
    }
  };
  const [countries, setCountries] = useState([]);

  const handleDownload = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the object URL after download
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      <ErrorToast message={`Error downloading the file: ${error}`}/>
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name"
        );
        setCountries(response.data);
      } catch (error) {
        <ErrorToast message={`Error fetching countries: ${error}`}/>
      }
    };

    fetchCountries();
  }, []);

  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { sm: "500px", xs: "700px", lg: "700px", md: "700px" },
          position: "relative",
        }}
      >
        <AppBar
          position="static"
          sx={{
            background: "transparent",
            boxShadow: "none",
          }}
        >
          <Toolbar sx={{ mt: 3}}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={edgentWhite}
                alt="Edgent Logo"
                sx={{
                  height: { lg: "60px", md: "60px", sm: "40px", xs: '40px' }, // Adjust height for different breakpoints
                  ml: { lg: "40px", md:'40px', sm: "20px", xs: "20px" }, // Adjust margin left for different breakpoints
                }}
              />
            </Link>
          </Toolbar>
        </AppBar>
        <HeroContent>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center", 
            }}
          >
            {/* <Box
              component="img"
              src={edgentWhite}
              alt="Edgent Logo"
              sx={{
                height: { xs: "60px", md: "80px" }, 
                mb: 2, 
              }}
            /> */}
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                color: "white",
                fontSize: { xs: "45px", md: "56px" },
                fontFamily: "Be Vietnam Pro",
                mb: 1, 
              }}
            >
              1:1 Mentorship
            </Typography>
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                color: "white",
                fontSize: { xs: "45px", md: "56px" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Tailored for Success
            </Typography>
            <Typography
              align="center"
              gutterBottom
              mt={2}
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: 400,
                width: "100%",
                textAlign: "center",
                fontFamily: "Be Vietnam Pro",
                marginTop: "16px",
              }}
            >
              Unlock your dream career through a personalized mentorship program
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "142px",
                fontSize: "18px",
                borderRadius: "50px",
                background: "linear-gradient(90deg, #0181BB 0%, #086690 100%)",
                color: "white",
                textTransform: "capitalize",
                fontFamily: "Be Vietnam Pro",
                marginTop: "16px",
              }}
              onClick={handleJoinNow}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Join Now"
              )}
            </Button>
          </Container>
        </HeroContent>
      </Box>
      <Container
        sx={{
          pt: 8,
          pb: 8,
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <Grid
          container
          justifyContent="center"
          width="100%"
          columnGap={3}
          sx={{
            "@media (min-width: 900px)": {
              position: "absolute",
              bottom: 50,
              left: 8,
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            justifyContent="center"
            mb={{ xs: 4, sm: 2 }}
          >
            <Card sx={{ borderRadius: "12px" }}>
              <Box
                p={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                columnGap={{ xs: 0, sm: 1 }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    src={AIIcon}
                    alt="AI"
                    style={{ width: "170px", height: "220px" }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "22px" },
                      fontWeight: 600,
                      color: "#202124",
                      fontFamily: "Be Vietnam Pro",
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    Artificial Intelligence & Machine Learning
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "center", sm: "flex-start" },
                      mt: 2,
                    }}
                    columnGap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: { xs: 1, sm: 0 },
                      }}
                    >
                      <img
                        src={mentor}
                        alt="Mentor"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        With Mentor
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={path}
                        alt="Path"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        Personalized Paths
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#DDF4FFBA",
                      color: "#0181BB",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      borderRadius: "6px",
                      width: "140px",
                      mt: 2,
                      fontFamily: "Be Vietnam Pro",
                    }}
                    onClick={() =>
                      handleDownload(
                        dataScienceCourseLink,
                        "Artificial_Intelligence_and_Machine Learning"
                      )
                    }
                  >
                    View Program
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            justifyContent="center"
            mb={{ xs: 4, sm: 2 }}
          >
            <Card sx={{ borderRadius: "12px" }}>
              <Box
                p={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                columnGap={{ xs: 0, sm: 1 }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    src={engineer}
                    alt="Engineer"
                    style={{ width: "170px", height: "220px" }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "22px" },
                      fontWeight: 600,
                      color: "#202124",
                      fontFamily: "Be Vietnam Pro",
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    Software Development Engineering
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "center", sm: "flex-start" },
                      mt: 2,
                    }}
                    columnGap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: { xs: 1, sm: 0 },
                      }}
                    >
                      <img
                        src={mentor}
                        alt="Mentor"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        With Mentor
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={path}
                        alt="Path"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        Personalized Paths
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#DDF4FFBA",
                      color: "#0181BB",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      borderRadius: "6px",
                      width: "140px",
                      mt: 2,
                      fontFamily: "Be Vietnam Pro",
                    }}
                    onClick={() =>
                      handleDownload(
                        SDECourseLink,
                        "Software_Development_Engineering"
                      )
                    }
                  >
                    View Program
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Box mt={"50px"}>
        <WhoIsItForContent />
      </Box>
      <Box mt={"120px"}>
        <EdgentOfferings />
      </Box>
      <Box mt={"120px"} sx={{ position: "relative" }}>
        <HowItWorks />
      </Box>
      <Box mt={"120px"} sx={{ backgroundColor: "#F1F6F9" }}>
        <MentorshipSection />
      </Box>
      <Box sx={{ backgroundColor: "#0181BB" }}>
        <CallToAction />
      </Box>
      <Box>
        <Footer />
      </Box>
    </div>
  );
};

export default NewHomepage;
