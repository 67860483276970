import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavbarLoggedInNew from 'components/Navbar-new/NavbarLoggedInNew';
import Calendar from 'Homepage/components/Calendar';
import CallScheduled from 'Homepage/components/CallScheduled';
import { baseURL } from "../aws-config";
import { setOnboardingData } from '../Homepage/OnboardingActions';
import ModalComponent from '../Homepage/components/RegistrationForm'; // Import your Modal component
import Loader from '../components/Loader'; // Import your Loader component
import ErrorToast from 'components/Toaster/ErrorToast';
import api from '../api'

const CalendarMeeting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token');
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${baseURL}workspace/onboarding-data/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const result = response?.data;        
        dispatch(setOnboardingData(result));
        localStorage.setItem('onboardingData', JSON.stringify(result));
      } catch (error) {
        <ErrorToast message={`Something went wrong: ${error}`} />;
      } finally {
        setLoading(false);
      }
    };
  
    if (accessToken) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [accessToken]);
  
  // Retrieve user profile and onboarding data from Redux store
  const onboardingData = useSelector((state) => state.onboarding.data);

  // Determine if the form is filled, a call is scheduled, and if the user is onboarded
  const isFormFilled = onboardingData?.form_filled || false;
  const isCallScheduled = onboardingData?.call_scheduled || false;
  const isOnboarded = onboardingData?.onboarded || false;
  useEffect(() => {
    if (isOnboarded) {
      navigate('/dashboard');
    }
  }, [onboardingData]);

  if (loading) {
    // Show a loading indicator while fetching data
    return <Loader />;
  }

  return (
    <>
      <NavbarLoggedInNew />
      <Grid container spacing={4} px={4}>
        <Grid item xs={12}>
          {!isOnboarded && (
            <>
              {!isFormFilled && !isOnboarded &&  <ModalComponent />}
              {isFormFilled && isCallScheduled &&  !isOnboarded && <CallScheduled />}
              {isFormFilled && !isCallScheduled &&  !isOnboarded && <Calendar />}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CalendarMeeting;
